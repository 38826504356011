import React from 'react'

const routes = [
  { path: '/fail', component: React.lazy(() => import('./views/CheckoutFailure')) },
  { path: '/error', component: React.lazy(() => import('./views/CheckoutError')) },
  { path: '/success', component: React.lazy(() => import('./views/CheckoutSuccess')) },
  { path: '/processing', component: React.lazy(() => import('./views/CheckoutPolling')) },
  { path: '/', exact: true, component: React.lazy(() => import('./views/CheckoutPayment')) },
  { path: '*', redirect: '/' }
]

export default routes
