const host = typeof window !== 'undefined' ? window.location.host : ''

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

export const isDevelopment = host.indexOf('-dev.') > -1
export const isSandbox = host.indexOf('-sandbox.') > -1
export const isProduction = typeof window !== 'undefined' && !isLocalhost && !isSandbox && !isDevelopment

const settings = {
  backendForLocalhost: 'https://pay-dev.speedy.io',
  // backendForLocalhost: 'https://pay-sandbox.speedy.io',
  languages: ['en', 'pt', 'es', 'pl']
}

export default settings
